import {NewsItemType} from "./types";

export const orderData = [
    'Рынок',
    'Wildberries',
    'Ozon',
    'Яндекс.Маркет',
    'Яндекс Маркет',  //???????????
    'AliExpress',
    'Мегамаркет',
    'Общие e-com',
    'WB SOV',
    'OZON SOV',
    'Ya.M SOV',
    'SMM SOV',

    'Самокат',
    'Яндекс.Лавка',
    'Яндекс Лавка',
    'Яндекс.Еда',
    'ВкусВилл',
    'Вкусвилл', //???????????????????????????????????
    'Купер',
    'Перекресток.Доставка',
    'Перекресток.Впрок',
    'Перекресток', //?????????????????????????????
    'X5',
    'Х5', //????????????????????????????
    'Пятерочка.Доставка',
    'Пятерочка',  //?????????????????????????????????
    'Магнит',
    'Магнит.Доставка',
    'Ozon fresh',
    'Ozon Fresh',  //???????????
    'WB Food',
    'Ozon Food',
    'Чижик', //??????????????????????
    'Общие e-groc',
    'Самокат SOV',
    'Я.Лавка SOV',
    'Купер SOV',
    'X5 SOV',
    'Магнит SOV'

]

export const sortSpmDataForNews = (data: NewsItemType[]) => {
    const newData: NewsItemType[] = []
    orderData.forEach((el) => {
        data.forEach((item) => {
            return item.spm === el ? newData.push(item):item
        })
    })
    return newData
}