import {lightColor} from "./optionsForColors";

export const gradient = 'linear-gradient(148.81deg, #0291ED -3.59%, #7DC35D 51.22%, #F1E812 90.91%, #ECE611 115.26%, #86C43F 154.59%, #1CA94D 188.62%)'


export const marketPlacesOptions = [
    {value: 'Рынок', label: 'Рынок', color: '#808080'},
    {value: 'Wildberries', label: 'Wildberries', color: '#C811aa'},
    {value: 'Ozon', label: 'Ozon', color: '#0059FF'},
    {value: 'Яндекс.Маркет', label: 'Яндекс.Маркет', color: lightColor},
    {value: 'AliExpress', label: 'AliExpress', color: '#e74e0a'},
    {value: 'Мегамаркет', label: 'Мегамаркет', color: '#24b23e'},
    {value: 'Доля E-grocery', label: 'Доля E-grocery', color: '#24b23e'},
    {value: 'Рынок млрд', label: 'Рынок млрд', color: '#bb92ba'},
    {value: 'Сбер Итого', label: 'Сбер Итого', color: '#23f800'},
    {value: 'Яндекс Итого', label: 'Яндекс Итого', color: '#ff8400'},
    {value: 'СберМегаМаркет Gross', label: 'СберМегаМаркет Gross', color: '#65b802'},
    {value: 'Яндекс.Маркет (Сплит)', label: 'Яндекс.Маркет (Сплит)', color: '#bb92ba'},
    {value: 'Общие e-com', label: 'Общие e-com', color: '#808080'},
]

export const defValueForMarketPlaces:string[] = []
marketPlacesOptions.map((el) => defValueForMarketPlaces.push(el.value))


export const superMarketsOptions = [
    {value: 'Рынок', label: 'Рынок', color: '#808080'},
    {value: 'Самокат', label: 'Самокат', color: '#ff335f'},
    {value: 'Яндекс.Лавка', label: 'Яндекс. Лавка', color: '#00bfdf'},
    {value: 'ВкусВилл', label: 'ВкусВилл', color: '#179c49'},
    {value: 'Купер', label: 'Купер', color: '#24b23e'},
    {value: 'Перекресток.Доставка', label: 'Перекресток. Доставка', color: '#005a27'},
    {value: 'Перекресток.Впрок', label: 'Перекресток. Впрок', color: '#95c93d'},
    {value: 'X5', label: 'X5', color: '#ff7c80'},
    {value: 'Магнит', label: 'Магнит', color: '#e30613'},
    {value: 'Яндекс.Еда', label: 'Яндекс.Еда', color: '#d6af2c'},
    {value: 'WB Food', label: 'Яндекс.Еда', color: '#C811aa'},
    {value: 'Ozon Food', label: 'Яндекс.Еда', color: '#0059FF'},
    {value: 'Пятерочка.Доставка', label: 'Пятерочка. Доставка', color: '#e52322'},
    {value: 'Рынок млрд', label: 'Рынок млрд', color: '#24b23e'},
    {value: 'Общие e-groc', label: 'Общие e-groc', color: '#808080'},

]

export const defValueForSuperMarkets:string[] = []
superMarketsOptions.map((el) => defValueForSuperMarkets.push(el.value))

export const second = ['Рынок','Рынок млрд','Доля E-grocery','Сбер Итого','Яндекс Итого','СберМегаМаркет Gross','Яндекс.Маркет (Сплит)']


//___________________________________________________________________priceIndex_________________________________________________________________________________

export const citiesForPrice = ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Новосибирск', 'Краснодар', 'Воронеж', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Самара', 'Челябинск']

export const spmForPrice = ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']

export const spmToCities = [
    {city: 'Москва', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']},
    {city: 'Санкт-Петербург', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']},
    {city: 'Екатеринбург', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка']},
    {city: 'Новосибирск', spm: ['Самокат', 'Яндекс Лавка', 'Пятерочка.Доставка', 'Магнит.Доставка']},
    {city: 'Краснодар', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']},
    {city: 'Воронеж', spm: ['Самокат', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка']},
    {city: 'Ростов-на-Дону', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']},
    {city: 'Казань', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Ozon fresh']},
    {city: 'Нижний Новгород', spm: ['Самокат', 'Яндекс Лавка', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка']},
    {city: 'Самара', spm: ['Самокат', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка']},
    {city: 'Челябинск', spm: ['Самокат', 'ВкусВилл', 'Пятерочка.Доставка', 'Магнит.Доставка', 'Яндекс Лавка']},
]

export const citiesToSpm = [
    {spm: 'Самокат', cities: ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Новосибирск', 'Краснодар', 'Воронеж', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Самара', 'Челябинск']},
    {spm: 'Яндекс Лавка', cities: ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Новосибирск', 'Краснодар', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Челябинск']},
    {spm: 'ВкусВилл', cities: ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Краснодар', 'Воронеж', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Самара', 'Челябинск']},
    {spm: 'Пятерочка.Доставка', cities: ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Новосибирск', 'Краснодар', 'Воронеж', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Самара', 'Челябинск']},
    {spm: 'Магнит.Доставка', cities: ['Москва', 'Санкт-Петербург', 'Екатеринбург', 'Новосибирск', 'Краснодар', 'Воронеж', 'Ростов-на-Дону', 'Казань', 'Нижний Новгород', 'Самара', 'Челябинск']},
    {spm: 'Ozon fresh', cities: ['Москва', 'Санкт-Петербург', 'Краснодар', 'Ростов-на-Дону', 'Казань']},
]

//_______________________________________________________________categories_____________________________________________________________________________________________

export const CheckBoxCategoriesShortList = [
    {value: 'Автотовары', label: 'Автотовары'},
    {value: 'Бытовая техника', label: 'Бытовая техника'},
    {value: 'Дом, сад, офис, школа', label: 'Дом, сад, офис, школа'},
    {value: 'Электроника', label: 'Электроника'},
    {value: 'Одежда и обувь', label: 'Одежда и обувь'},
    {value: 'Продукты питания', label: 'Продукты питания'},
    {value: 'Красота, аптека, здоровье', label: 'Красота, аптека, здоровье'},
    {value: 'Обустройство дома и инструмент', label: 'Обустройство дома и инструмент'},
    //{value: 'Зоотовары', label: 'Зоотовары'},
    //{value: 'Книги', label: 'Книги'},
    //{value: 'Детские товары и игрушки', label: 'Детские товары и игрушки'},
    //{value: 'Мебель', label: 'Мебель'},



    //{value: 'Спорт', label: 'Спорт'},

    //{value: 'Ювелирные изделия', label: 'Ювелирные изделия'},
]

export const CheckBoxCategoriesList = [
    {value: 'Автотовары', label: 'Автотовары'},
    {value: 'Бытовая техника', label: 'Бытовая техника'},
    {value: 'Детские товары и игрушки', label: 'Детские товары и игрушки'},
    {value: 'Дом, сад, офис, школа', label: 'Дом, сад, офис, школа'},
    {value: 'Зоотовары', label: 'Зоотовары'},
    {value: 'Книги', label: 'Книги'},
    {value: 'Красота, аптека, здоровье', label: 'Красота, аптека, здоровье'},
    {value: 'Мебель', label: 'Мебель'},
    {value: 'Обустройство дома и инструмент', label: 'Обустройство дома и инструмент'},
    {value: 'Одежда и обувь', label: 'Одежда и обувь'},
    {value: 'Продукты питания', label: 'Продукты питания'},
    {value: 'Спорт', label: 'Спорт'},
    {value: 'Электроника', label: 'Электроника'},
    {value: 'Ювелирные изделия', label: 'Ювелирные изделия'},
]