export type ColorsObjectType = {
    name: string
    color: string
    altColor?: string
}

export const lightColor = '#efbc0b'
export const textColor = '#000000'

export const marketPlacesColors: ColorsObjectType[] = [
    {name: 'Рынок', color: '#808080', altColor: 'rgba(128, 128, 128, 1)'},
    {name: 'Wildberries',  color: '#C811aa', altColor: 'rgba(200, 17, 170, 1)'},
    {name: 'Ozon', color: '#0059FF', altColor: 'rgba(0, 89, 255, 1)'},
    {name: 'AliExpress',  color: '#e74e0a', altColor: 'rgba(231, 78, 10, 1)'},
    {name: 'Яндекс.Маркет',  color: lightColor, altColor: '#efbc0b'},
    {name: 'Яндекс Маркет',  color: lightColor, altColor: '#efbc0b'},  //????????
    {name: 'Мегамаркет', color: '#24b23e', altColor: 'rgba(36, 178, 62, 1)'},
    {name: 'Общие e-com', color: '#808080', altColor: '#808080'},
    {name: 'Доля E-grocery', color: '#24b23e', altColor: 'rgba(36, 178, 62, 1)'},
    {name: 'Рынок млрд', color: '#ECE611', altColor: 'rgba(236, 230, 17, 1)'},
    {name: 'Сбер Итого', color: '#23f800', altColor: 'rgba(35, 248, 0, 1)'},
    {name: 'Яндекс Итого', color: '#ff8400', altColor: 'rgba(255, 132, 0, 1)'},
    {name: 'СберМегаМаркет Gross', color: '#65b802', altColor: 'rgba(101, 184, 2, 1)'},
    {name: 'Яндекс.Маркет (Сплит)', color: '#bb92ba', altColor: 'rgba(187, 146, 186, 1)'},
    {name: 'Ya.M SOV', color: lightColor, altColor: '#ffb301'},
    {name: 'OZON SOV', color: '#0059FF', altColor: 'rgba(0, 89, 255, 1)'},
    {name: 'SMM SOV', color: '#24b23e', altColor: 'rgba(36, 178, 62, 1)'},
    {name: 'WB SOV', color: '#C811aa', altColor: 'rgba(200, 17, 170, 1)'},
];

export const superMarketsColors: ColorsObjectType[] = [
    {name: 'Рынок',  color: '#808080', altColor: 'rgba(128, 128, 128, 1)'},
    {name: 'Магнит.Доставка',  color: '#e30613', altColor: 'rgba(227, 6, 19, 1)'},
    {name: 'Магнит',  color: '#e30613', altColor: 'rgba(227, 6, 19, 1)'},
    {name: 'Самокат',  color: '#ff335f', altColor: 'rgba(255, 51, 95, 1)'},
    {name: 'WB Food',  color: '#C811aa', altColor: 'rgba(200, 17, 170, 1)'},
    {name: 'Ozon Food', color: '#0059FF', altColor: 'rgba(0, 89, 255, 1)'},
    {name: 'Яндекс.Лавка',  color: '#00bfdf', altColor: 'rgba(0, 191, 223, 1)'},
    {name: 'Яндекс Лавка',  color: '#00bfdf', altColor: 'rgba(0, 191, 223, 1)'},
    {name: 'Яндекс.Маркет15',  color: lightColor, altColor: '#efbc0b'},
    {name: 'Ozon fresh',  color: '#0059FF', altColor: 'rgba(0, 89, 255, 1)'},
    {name: 'Ozon Fresh',  color: '#0059FF', altColor: 'rgba(0, 89, 255, 1)'},
    {name: 'Пятёрочка',  color: '#ff7c80', altColor: 'rgba(255, 124, 128, .1)'},
    {name: 'Пятерочка',  color: '#ff7c80', altColor: 'rgba(255, 124, 128, 1)'},
    {name: 'Общие e-groc',  color: '#808080', altColor: '#808080'},
    {name: 'Яндекс.Еда',  color: '#d6af2c', altColor: 'rgba(214, 175, 44, 1)'},
    {name: 'ВкусВилл', color: '#179c49', altColor: 'rgba(23, 156, 73, 1)'},
    {name: 'Вкусвилл', color: '#179c49', altColor: 'rgba(23, 156, 73, 1)'},
    {name: 'Перекресток.Доставка', color: '#005a27', altColor: 'rgba(0, 90, 39, 1)'},
    {name: 'Перекресток', color: '#005a27', altColor: 'rgba(0, 90, 39, 1)'},
    {name: 'Перекресток.Впрок', color: '#95c93d', altColor: 'rgba(149, 201, 61, 1)'},
    {name: 'Пятерочка.Доставка', color: '#ff7c80', altColor: 'rgba(255, 124, 128, 1)'},
    {name: 'Купер', color: '#89AC76', altColor: 'rgba(137, 172, 118, 1)'},
    {name: 'X5', color: '#ff7c80', altColor: 'rgba(255, 124, 128, 1)'},
    {name: 'Х5', color: '#ff7c80', altColor: 'rgba(255, 124, 128, 1)'},
    {name: 'Чижик', color: '#ff8000', altColor: 'rgba(255, 128, 0, 1)'},
    {name: 'X5 SOV', color: '#ff7c80', altColor: 'rgba(255, 124, 128, 1)'},
    {name: 'Купер SOV', color: '#89AC76', altColor: 'rgba(137, 172, 118, 1)'},
    {name: 'Самокат SOV', color: '#ff335f', altColor: 'rgba(255, 51, 95, 1)'},
    {name: 'Я.Лавка SOV', color: '#00bfdf', altColor: 'rgba(0, 191, 223, 1)'},
    {name: 'Вкусвилл SOV', color: '#179c49', altColor: 'rgba(23, 156, 73, 1)'},
    {name: 'Магнит SOV', color: '#e30613', altColor: 'rgba(227, 6, 19, 1)'},
    {name: 'Рынок млрд', color: '#ECE611', altColor: 'rgba(236, 179, 17, 1)'},
];

export const colorsCategories = [
    '#007934',
    '#FF6700',
    '#800000',
    '#DCFF00',
    '#12127D',
    '#FF3E6F',
    '#058789',
    '#F17950',
    '#00F4FF',
    '#D2C04B',
    '#C7307B',
    '#E4DFEC',
    '#41733A',
    '#85B80F',
    '#FF6700',
    '#795E20',
    '#A3EFC0',
    '#00F4FF',]

