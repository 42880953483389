import React, {useEffect} from 'react';
import {useAppDispatch, useAppSelector} from "../../../../redux/store";
import {fetchDataAllPrice, fetchDataCityPrice, fetchDataPrice} from "../../../../redux/priceReducer";
import {
    collapseChart,
    selectChart,
    selectCitiesPriceColumnCityChart,
    selectDataPriceColumnCityChart,
    selectDataPriceColumnForAllChart,
    selectDataPriceLineChart,
    selectOrderSpm,
    selectSpmPriceColumnCityChart,
    selectSpmPriceColumnForAllChart, selectSpmPriceForLineChart,
    selectTimePriceColumnCityChart,
    selectTimePriceColumnForAllChart,
    selectTimePriceLineChart
} from "../../../../common/selectors";
import {PriceLineChart} from "./priceLineChart";
import {PriceColumnChart} from "./priceColumnChart";
import {EmptyComponent} from "../../../../common/empty";
import dayjs from "dayjs";

export const PriceCharts = () => {

    const dispatch = useAppDispatch()

    const time = useAppSelector(selectTimePriceLineChart)
    const timeAll = useAppSelector(selectTimePriceColumnForAllChart)
    const timeCity = useAppSelector(selectTimePriceColumnCityChart)

    const spmLineChart = useAppSelector(selectSpmPriceForLineChart)

    const spmAll = useAppSelector(selectSpmPriceColumnForAllChart)
    const orderSpm = useAppSelector(selectOrderSpm)

    const spmCity = useAppSelector(selectSpmPriceColumnCityChart)
    const city = useAppSelector(selectCitiesPriceColumnCityChart)

    const data = useAppSelector(selectDataPriceLineChart)
    const dataAll = useAppSelector(selectDataPriceColumnForAllChart)
    const dataCity = useAppSelector(selectDataPriceColumnCityChart)


    const collapse = useAppSelector(collapseChart)

    const chart = useAppSelector(selectChart)

    let paramLineChart = ''
    spmLineChart.forEach((el) => paramLineChart += el + ',')

    let paramSupermarkets = ''
    spmAll.forEach((el) => paramSupermarkets += el + ',')

    let paramCities = ''
    city.forEach((el) => paramCities += el + ',')

    useEffect(() => {
        dispatch(fetchDataPrice({from_date: time[0], to_date:dayjs(time[1]).day(7).format('YYYY-MM-DD'), seller_name: paramLineChart}))
        dispatch(fetchDataAllPrice({from_date: timeAll[0], to_date: timeAll[1], seller_name: paramSupermarkets}))
        dispatch(fetchDataCityPrice({
            from_date: timeCity[0],
            to_date: timeCity[1],
            seller_name: spmCity,
            city_name: paramCities
        }))
    }, [time, timeAll, timeCity, dispatch, paramSupermarkets, paramCities, spmCity, paramLineChart])

    const getTitle = (date: string[]) => {
        if (dayjs(date[0]).valueOf() < 1735506000000) {
            return [
                `${chart} по РФ`,
                `${chart} по городам`
            ]
        }
        else {
            return [
                `${chart} по Москве`,
                `${chart} по городам`
            ]
        }
    }

    return (
        <div style={{height: '100%'}}>
            {collapse.includes('1')&&(data.length ? <PriceLineChart data={data}
                                    time={time}
                                    title={getTitle(time)[0]}
            /> : <EmptyComponent/>)}
            {collapse.includes('2')&&(dataAll.length ? <PriceColumnChart data={dataAll}
                               time={timeAll}
                               title={getTitle(timeAll)[0]}
                               categoriesXaxis={orderSpm}
                               chart={chart}
                               typeChart={'spm'}
            /> : <EmptyComponent/>)}
            {collapse.includes('3')&&(dataCity.length ? <PriceColumnChart data={dataCity}
                               time={timeCity}
                               title={getTitle(timeCity)[1]}
                               categoriesXaxis={city}
                               chart={chart}
                               typeChart={'city'}
                               spmCity={spmCity}
            /> : <EmptyComponent/>)}
        </div>
    );
};

